<template>
  <div>
    <a-button-group>
      <a-popconfirm title="确定删除吗" @confirm="destroy">
        <a-button type="danger" icon="delete" size="small">删除</a-button>
      </a-popconfirm>
    </a-button-group>
  </div>
</template>

<script>
import { sortingRecordDestroy } from "@/apis/sorting";

export default {
  props: ["item"],
  methods: {
    destroy() {
      sortingRecordDestroy({ id: this.item.id }).then(() => {
        this.$message.success("删除成功");
        this.$emit("destroy", this.item);
      });
    },
  },
};
</script>

<style scoped></style>
